import React, { useState } from "react";
import { useEffect } from "react";
import ImageUploader from "../components/ImageUploader";
import LeafletMap from "../components/LeafletMap";
import SingleSelectInput from "../components/SingleSelectInput";
import { answererRelation } from "../data/advanceSearch";
import {
	answererName,
	answererRelationQuestion,
	gharNumber,
	houseOwnerContactQn,
	houseOwnerCitizenshipQn,
	houseOwnerNameQn,
	ifOtherValueQuestion,
	latitude,
	livingType,
	livingtypeQuestion,
	longitude,
	toleFiveQn,
	toleFourQn,
	toleOneQn,
	toleThreeQn,
	toleTwoQn,
	toleWardFive,
	toleWardFour,
	toleWardOne,
	toleWardThree,
	toleWardTwo,
} from "../data/houseSurvey";
import { selectedLanguage, ward, wardQuestion } from "../data/institute";
import { checkIfHouseExists } from "../modules/HomeSurvey/homeSurveyCrud";

const GhardhuriForm = (props) => {
	const [gharNumberExists, setGharNumberExists] = useState(true);
	const [currentWardNumber, setCurrentWardNumber] = useState(0);
	useEffect(() => {
		const ward = parseInt(sessionStorage.getItem("ward"));
		if (ward) {
			setCurrentWardNumber(ward);
		} else {
			setCurrentWardNumber(0);
		}
	}, []);

	const wardData = [
		{
			question: toleOneQn,
			toleList: toleWardOne,
		},
		{
			question: toleTwoQn,
			toleList: toleWardTwo,
		},
		{
			question: toleThreeQn,
			toleList: toleWardThree,
		},
		{
			question: toleFourQn,
			toleList: toleWardFour,
		},
		{
			question: toleFiveQn,
			toleList: toleWardFive,
		},
	];
	const [selectedWard, setSelectedWard] = useState(0);
	console.log("form props", props);
	const { formik } = props;
	const { primaryHouse } = formik.values;
	const { zoom, lat, lng } = formik.values;
	const handleWardChange = (e) => {
		console.log(e.target.value);
		setSelectedWard(e.target.value - 1);
		formik.handleChange(e);
	};
	const addMarker = (e) => {
		console.log(e);
	};
	const handleHouseNumberChange = (e) => {
		formik.handleChange(e);
		checkIfHouseExists(e.target.value).then((res) => {
			console.log(res);
			if (!res.data.success) {
				setGharNumberExists(false);
			} else {
				setGharNumberExists(true);
			}
		});
	};
	return (
		<>
			<div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
				<div class="col-xl-12 col-xxl-8">
					<div
						class="pb-5"
						data-wizard-type="step-content"
						data-wizard-state="current"
					>
						<h3 class="mb-10 font-weight-bold text-dark">घरधुरी विवरण</h3>
						<div className="row">
							<div className="col-lg-6">
								<div class="form-group">
									<label>{wardQuestion[selectedLanguage]}</label>
									{currentWardNumber === 0 ? (
										<SingleSelectInput
											id="ward"
											name="primaryHouse.wardNumber"
											value={primaryHouse.wardNumber}
											formik={formik}
											selectList={ward}
											handleWardChange={handleWardChange}
										/>
									) : (
										<input
											readOnly
											type="text"
											class="form-control form-control-solid form-control-lg"
											onChange={formik.handleChange}
											name="primaryHouse.wardNumber"
											value={
												primaryHouse.wardNumber
													? primaryHouse.wardNumber
													: currentWardNumber
											}
										/>
									)}
								</div>
							</div>
							<div className="col-lg-6">
								<div className="form-group">
									<label>
										{
											wardData[
												currentWardNumber == 0
													? selectedWard
													: currentWardNumber - 1
											].question[selectedLanguage]
										}
									</label>
									<SingleSelectInput
										id="ward"
										name="primaryHouse.toleName"
										value={primaryHouse.toleName}
										formik={formik}
										selectList={
											wardData[
												currentWardNumber == 0
													? selectedWard
													: currentWardNumber
											].toleList
										}
									/>
								</div>
							</div>
							<div className="col-lg-6">
								<div class="form-group">
									<label>{houseOwnerNameQn[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control form-control-solid form-control-lg"
										onChange={formik.handleChange}
										name="primaryHouse.houseOwnerName"
										value={primaryHouse.houseOwnerName}
									/>
								</div>
							</div>
							<div className="col-lg-6">
								<div class="form-group">
									<label>{houseOwnerContactQn[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control form-control-solid form-control-lg"
										onChange={formik.handleChange}
										name="primaryHouse.houseOwnerContact"
										value={primaryHouse.houseOwnerContact}
									/>
								</div>
							</div>
							<div className="col-lg-6">
								<div class="form-group">
									<label>{houseOwnerCitizenshipQn[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control form-control-solid form-control-lg"
										onChange={formik.handleChange}
										name="primaryHouse.citizenshipNumber"
										value={primaryHouse.citizenshipNumber}
										style={{ fontFamily: "Arial" }}
									/>
								</div>
							</div>
							<div className="col-lg-6">
								<div class="form-group">
									<label>{gharNumber[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control form-control-solid form-control-lg"
										onChange={handleHouseNumberChange}
										name="primaryHouse.gharNumber"
										value={primaryHouse.gharNumber}
										style={{ fontFamily: "Arial" }}
									/>
									{!primaryHouse.gharNumber && (
										<p className="invalid-feedback">
											कृपया अंग्रेजीमा अद्वितीय घर नम्बर चयन गर्नुहोस्
										</p>
									)}
									{primaryHouse.gharNumber && gharNumberExists && (
										<p className="invalid-feedback">
											घर नम्बर पहिले नै लिइएको छ।
										</p>
									)}
								</div>
							</div>
							<div className="col-lg-6">
								<div class="form-group">
									<label>{answererName[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control form-control-solid form-control-lg"
										onChange={formik.handleChange}
										name="primaryHouse.answererName"
										value={primaryHouse.answererName}
									/>
								</div>
							</div>
							<div className="col-lg-6">
								<div class="form-group">
									<label>{answererRelationQuestion[selectedLanguage]}</label>
									<SingleSelectInput
										id="answererRelation"
										name="primaryHouse.answererRelation"
										value={primaryHouse.answererRelation}
										formik={formik}
										selectList={answererRelation}
									/>
								</div>
							</div>
							{primaryHouse.answererRelation == "other" && (
								<div className="col-lg-6">
									<div class="form-group">
										<label>{ifOtherValueQuestion[selectedLanguage]}</label>
										<input
											type="text"
											class="form-control"
											name="primaryHouse.answererRelationOther"
											onChange={formik.handleChange}
											value={primaryHouse.answererRelationOther}
										/>
									</div>
								</div>
							)}
							<div className="col-lg-6">
								<div class="form-group">
									<label>{livingtypeQuestion[selectedLanguage]}</label>
									<SingleSelectInput
										id="livingType"
										name="primaryHouse.livingType"
										value={primaryHouse.livingType}
										formik={formik}
										selectList={livingType}
									/>
								</div>
							</div>
							{primaryHouse.livingType == "other" && (
								<div className="col-lg-6">
									<div class="form-group">
										<label>{ifOtherValueQuestion[selectedLanguage]}</label>
										<input
											type="text"
											class="form-control"
											name="primaryHouse.livingTypeOther"
											onChange={formik.handleChange}
											value={primaryHouse.livingTypeOther}
										/>
									</div>
								</div>
							)}
							{primaryHouse.gharNumber && !gharNumberExists && (
								<div className="col-lg-6">
									<div class="form-group">
										<label>घरको फोटो</label>
										<ImageUploader
											fileListContent={props.fileListContent}
											uniqueIdentifier={primaryHouse.gharNumber}
											imageName="house"
										/>
									</div>
								</div>
							)}
						</div>
						<div className="row">
							<div className="col-lg-6">
								<div class="form-group">
									<label>{latitude[selectedLanguage]}</label>
									<input
										type="number"
										class="form-control form-control-solid form-control-lg"
										name="lat"
										placeholder={longitude[selectedLanguage]}
										value={formik.values.lat}
										onChange={formik.handleChange}
									/>
								</div>
							</div>
							<div className="col-lg-6">
								<div class="form-group">
									<label>{longitude[selectedLanguage]}</label>
									<input
										type="number"
										class="form-control form-control-solid form-control-lg"
										name="lng"
										placeholder={longitude[selectedLanguage]}
										value={formik.values.lng}
										onChange={formik.handleChange}
									/>
								</div>
							</div>
							<div className="col-12">
								<div style={{ height: "50vh" }}>
									<LeafletMap
										zoom={zoom || 15}
										// position={position}
										lat={lat}
										lng={lng}
										addMarker={addMarker}
									>
										<p>
											स्थान प्राप्त गर्न<em>मार्कर</em> पिन गर्नुहोस
										</p>
									</LeafletMap>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default GhardhuriForm;
