import React, { useEffect, useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import ApexCharts from "apexcharts";
import { Accordion, Button, Card } from "react-bootstrap";
import { KTUtil } from "../../_metronic/_assets/js/components/util.js";
import { useHtmlClassService } from "../../_metronic/layout";
import objectPath from "object-path";
import SchoolGraph from "../detailTypes/overview-sections/SchoolGraph";
import axios from "axios";
import AgricultureBarGraphCard from "../detailTypes/overview-sections/AgricultureBarGraphCard.js";
import { agricultureList } from "../data/agricultureReport.js";
import { selectedLanguage } from "../data/institute.js";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import getNepalNumber from "get-nepali-number";
import Loader from "../components/Loader.js";
// import Axios from 'axios';

const AgricultureDataDetail = (props) => {
	const uiService = useHtmlClassService();
	const [data, setData] = useState(undefined);
	const [loading, setLoading] = useState(true);
	const layoutProps = useMemo(() => {
		return {
			colorsGrayGray500: objectPath.get(
				uiService.config,
				"js.colors.gray.gray500"
			),
			colorsGrayGray200: objectPath.get(
				uiService.config,
				"js.colors.gray.gray200"
			),
			colorsGrayGray300: objectPath.get(
				uiService.config,
				"js.colors.gray.gray300"
			),
			colorsThemeBaseDanger: objectPath.get(
				uiService.config,
				"js.colors.theme.base.danger"
			),
			fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
		};
	}, [uiService]);
	const child = agricultureList[props.match.params.parentIndex]?.child.find(
		(i) => i.value === props.match.params.apiKey
	);
	const getTotal = (datum) => {
		let total = 0;
		if (datum.data.total) {
			datum.data.total.map((value, index) => {
				total += value.value;
			});
			if (parseInt(datum.ward) !== 0) {
				return (
					<td>
						{selectedLanguage == "nepali" ? getNepalNumber(total) : total}
					</td>
				);
			} else {
				return (
					<th>
						{selectedLanguage == "nepali" ? getNepalNumber(total) : total}
					</th>
				);
			}
		} else {
			datum.data.map((value, index) => {
				total += value.value;
			});
			if (parseInt(datum.ward) !== 0) {
				return (
					<td>
						{selectedLanguage == "nepali" ? getNepalNumber(total) : total}
					</td>
				);
			} else {
				return (
					<th>
						{selectedLanguage == "nepali" ? getNepalNumber(total) : total}
					</th>
				);
			}
		}
	};
	const clickDiv = (el) => {
		const downloadBtn = document.getElementById("test-table-xls-button");
		if (downloadBtn) {
			downloadBtn.click();
		}
	};
	// useEffect(() => {
	//     let call = axios.create({
	//         baseURL: 'https://kc.humanitarianresponse.info/api/v1/data/730010?format=json',
	//         responseType: 'application/json', // default is json
	//         auth: {
	//             username: 'bheemdattamun',
	//             password: 'bheemdattamun1234#!@'
	//         },
	//         headers: {
	//             'Content-Type': 'application/json',
	//             'Connection': ' Keep-Alive',
	//             // 'Authorization': 'Token dc962add3df80baae513775097e1641fb75c4946'
	//         }
	//     });
	//     call.get('').then((response) => {
	//         console.log(response);
	//     }).catch((error) => {
	//         console.log(error);
	//     });

	//     // fetch("https://kc.kobotoolbox.org/attachment/original?media_file=user_name/attachments/image_name", {
	//     //     // body: "param1=someValue&m2=somOtherValue",
	//     //     headers: {
	//     //         'Content-Type': 'application/json',
	//     //         "Authorization": `Basic  ${btoa("bheemdattamun:bheemdattamun1234#!@")}`,
	//     //         'Access-Control-Allow-Origin': "*",
	//     //         'Connection': ' Keep-Alive',
	//     //         // 'Authorization': 'Token dc962add3df80baae513775097e1641fb75c4946'
	//     //     },
	//     //     method: "GET"
	//     // }).then(res => console.log(res));
	//     const element1 = document.getElementById("school_chart");
	//     renderChart(element1, layoutProps)
	// }, [layoutProps]);
	const parentIndex = props.match.params.parentIndex;
	const genderKeys = [
		{ value: "male", label: "पुरुष" },
		{ value: "female", label: "महिल" },
		{ value: "third", label: "अन्य " },
		{ value: "single", label: "जम्मा" },
	];
	return (
		<>
			<Card>
				{/* <Card.Header>
                    <h2>
                        शिक्षा सम्बन्धी बिबरण
                    </h2>
                </Card.Header> */}
				<Card.Body>
					<Accordion defaultActiveKey={0}>
						<AgricultureBarGraphCard
							child={child}
							detail={true}
							parentIndex={parentIndex}
							childIndex={0}
							setData={setData}
							setLoading={setLoading}
						/>
					</Accordion>
				</Card.Body>
			</Card>
			{!loading && data && data.chartAvailable && (
				<Card className="mt-10">
					<Card.Header className="d-flex justify-content-between">
						<h2>{child[selectedLanguage]}</h2>
						<div>
							<ReactHTMLTableToExcel
								id="test-table-xls-button"
								className="d-none"
								table="detail-table"
								filename={child[selectedLanguage]}
								sheet={child[selectedLanguage]}
								buttonText="Download as XLS"
							/>
							<button className="btn btn-light-success" onClick={clickDiv}>
								{selectedLanguage == "nepali"
									? "डाउनलोडगर्नुहोस् XLS"
									: "Export XLS"}
								<i
									className="far fa-file-excel ml-2"
									style={{ position: "relative" }}
								></i>
							</button>
							{/* <button className="btn btn-light-danger" onClick={this.exportPdf}>
                                    Export PDF
                                <i className="far fa-file-pdf ml-2" style={{ top: "3px", position: "relative" }}></i>
                                </button> */}
						</div>
					</Card.Header>

					{loading && <Loader loading={loading} />}
					{!loading && (
						<Card.Body>
							{data?.ward && (
								<Table responsive id="detail-table">
									<thead>
										<tr>
											<th>
												{selectedLanguage == "nepali" ? "वडा न " : "Ward No. "}
											</th>
											{data.ward.length &&
												data.ward[0].data.male &&
												data.ward[0].data.male.map((datum, index) => {
													return <th>{datum.label}</th>;
												})}
											{data.ward.length &&
												!data.ward[0].data.male &&
												data.ward[0].data.map((datum, index) => {
													return <th>{datum.label}</th>;
												})}
											{/* <th>{selectedLanguage == 'nepali' ? "जम्मा" : "Total"}</th> */}
										</tr>
									</thead>
									<tbody>
										{data.ward.length &&
											data.ward.map((datum, index) => {
												return (
													<tr key={index}>
														<th>
															{parseInt(datum.ward) !== 0
																? selectedLanguage == "nepali"
																	? getNepalNumber(datum.ward)
																	: datum.ward
																: selectedLanguage == "nepali"
																? "मल्लरानी"
																: "Rural Municipality City"}
														</th>
														{datum.data.total &&
															datum.data.total.map((valueObj, index) => {
																if (parseInt(datum.ward) !== 0) {
																	return (
																		<td key={index}>
																			{selectedLanguage == "nepali"
																				? getNepalNumber(valueObj.value)
																				: valueObj.value}
																		</td>
																	);
																} else {
																	return (
																		<th key={index}>
																			{selectedLanguage == "nepali"
																				? getNepalNumber(valueObj.value)
																				: valueObj.value}
																		</th>
																	);
																}
															})}
														{!datum.data.total &&
															datum.data.map((valueObj, index) => {
																if (parseInt(datum.ward) !== 0) {
																	return (
																		<td key={index}>
																			{selectedLanguage == "nepali"
																				? getNepalNumber(valueObj.value)
																				: valueObj.value}
																		</td>
																	);
																} else {
																	return (
																		<th key={index}>
																			{selectedLanguage == "nepali"
																				? getNepalNumber(valueObj.value)
																				: valueObj.value}
																		</th>
																	);
																}
															})}
														{/* {
                                                // this.state
                                                getTotal(datum)
                                            } */}
													</tr>
												);
											})}
									</tbody>
								</Table>
							)}
							{data?.male && (
								<Table responsive id="detail-table">
									<thead>
										<tr>
											<th>
												{selectedLanguage == "nepali" ? "लिङ्ग" : "Gender"}
											</th>
											{data.male &&
												data.male.length &&
												data.male.map((datum, index) => {
													return <th>{datum.label}</th>;
												})}
											{/* <th>{selectedLanguage == 'nepali' ? "जम्मा" : "Total"}</th> */}
										</tr>
									</thead>
									<tbody>
										{genderKeys.map((gender, index) => (
											<tr key={index}>
												<th>{gender.label}</th>
												{data[gender.value]?.map((valueObj, index2) => {
													if (gender.value !== "single") {
														return (
															<td key={index2}>
																{selectedLanguage == "nepali"
																	? getNepalNumber(valueObj.value)
																	: valueObj.value}
															</td>
														);
													} else {
														return (
															<th key={index2}>
																{selectedLanguage == "nepali"
																	? getNepalNumber(valueObj.value)
																	: valueObj.value}
															</th>
														);
													}
												})}
												{/* {
                                            // this.state
                                            getTotal(data.gender)
                                        } */}
											</tr>
										))}
									</tbody>
								</Table>
							)}
						</Card.Body>
					)}
				</Card>
			)}
		</>
	);
};

export default AgricultureDataDetail;
