import axiosHandler from "../../../redux/axiosHandler";

export function uploadImage(data) {
	return axiosHandler.post("/imageUpload/uploadImages", data);
}
export function removeImage(fileName) {
	return axiosHandler.get(`/imageUpload/deleteImages/${fileName}`);
}
export function constructMessage(messageBody) {
	return axiosHandler.post("/sms/message/construct", messageBody);
}
