import { allToleName } from "../houseSurvey";
import { selectedLanguage } from "../institute";
import {
	toleWardFive,
	toleWardFour,
	toleWardOne,
	toleWardThree,
	toleWardTwo,
} from "../houseSurvey";
export const ToleNameFetchByWard = (toleName, ward) => {
	switch (ward) {
		case 1:
			return toleWardOne.find((i) => i.value === toleName)
				? toleWardOne.find((i) => i.value === toleName)[selectedLanguage]
				: "";

		case 2:
			return toleWardTwo.find((i) => i.value === toleName)
				? toleWardTwo.find((i) => i.value === toleName)[selectedLanguage]
				: "";

		case 3:
			return toleWardThree.find((i) => i.value === toleName)
				? toleWardThree.find((i) => i.value === toleName)[selectedLanguage]
				: "";

		case 4:
			return toleWardFour.find((i) => i.value === toleName)
				? toleWardFour.find((i) => i.value === toleName)[selectedLanguage]
				: "";

		case 5:
			return toleWardFive.find((i) => i.value === toleName)
				? toleWardFive.find((i) => i.value === toleName)[selectedLanguage]
				: "";

		default:
			return "";
	}
};
export const ToleNameFetch = (toleName) => {
	return allToleName.find((i) => i.value === toleName)
		? allToleName.find((i) => i.value === toleName)[selectedLanguage]
		: "";
};
