import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { FileDropZone } from "../../_metronic/layout/components/dropzone/FileDropZone";
import {
	uploadImage,
	removeImage,
} from "../modules/Miscellanous/miscellanousActions";
import { SubCatRoute } from "../providers/SubCatRouteProvider";
const ImageUploader = (props) => {
	const { route, fileListContent } = useContext(SubCatRoute);
	const [previewImage, setpreviewImage] = useState("");
	const [uploadData, setuploadData] = useState({});
	const [apiData, setApiData] = useState({
		name: "",
		imageName: "",
		value: [],
	});
	useEffect(() => {
		if (apiData.value.length > 0 && apiData.name !== "")
			handleUpload(apiData.name, apiData.imageName);
		if (apiData.value.length === 0 && apiData.name !== "")
			removeUpload(apiData.name, apiData.imageName);
	}, [uploadData]);
	useEffect(() => {
		console.log(fileListContent);
	});
	const handleChangeFile = (name, value, imageName) => {
		if (props.imagename) {
			imageName = props.imageName;
		}
		setpreviewImage(URL.createObjectURL(value[0]));
		console.log(route);
		//name ma house and value ma list of filelist
		setApiData({
			value,
			name,
			imageName,
		});
		setuploadData({
			fileList: {
				[name]: {
					image: value,
					fileName: props.uniqueIdentifier + "_" + imageName + ".jpg",
				},
			},
		});
	};

	// upload image
	const handleUpload = (name, imageName) => {
		// const fileList = props.fileListContent[name];
		const data = new FormData();
		const imageObject = uploadData.fileList[apiData.name].image[0];

		let fileName = props.uniqueIdentifier + "_" + imageName + ".jpg";
		data.append("file", imageObject, fileName);
		uploadImage(data)
			.then((res) => {
				// then print response status
				props.setFileList(uploadData);
				console.log("upload successfully.");
				console.log(res);
			})
			.catch((err) => console.log("upload failed."));
	};

	// remove uploaded image
	const removeUpload = (imageName) => {
		let fileName = props.uniqueIdentifier + "_" + imageName + ".jpg";
		removeImage(fileName)
			.then((res) => {
				// then print response status
				console.log("deleted successfully.");
				console.log(res.statusText);
			})
			.catch((err) => console.log("upload failed."));
	};
	const filesAdded = (files) => {
		handleChangeFile(`${route}Image`, files, route);
	};
	return (
		<>
			{previewImage == "" ? (
				<FileDropZone filesAdded={filesAdded} />
			) : (
				<div className="row">
					<div className="col-12">
						<div className="image-to-upload p-3">
							<img height="150" src={previewImage} alt="" />
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ImageUploader;
