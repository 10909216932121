import axios from "axios";
import { shallowEqual, useSelector } from "react-redux";
import { getStoredState } from "redux-persist";
import store from "./store";

// DO export info
// export const BASE_URL = "https://mallaranim.hamropalika.org";
export const BASE_URL = process.env.REACT_APP_URL;
// const { isAuthorized } = useSelector(
//     ({ auth }) => ({
//         isAuthorized: auth.user != null,
//     }),
//     shallowEqual
// );
export const getAuthToken = () => {
	const token = store.getState().auth.token;
	// const { token } = useSelector(
	//     ({ auth }) => ({
	//         token: auth.user.accessToken,
	//     }),
	//     shallowEqual
	// )
	console.log(token);
	return token;
};

// DO export info
//export const baseURLAPI = "https://mallaranim.hamropalika.org/api";
export const baseURLAPI = process.env.REACT_APP_URL+"/api";
export const siteURL = "https://mallaraniprofile.hamropalika.org";
export default axios.create({
	baseURL: baseURLAPI,
	// headers: {
	//     Authorization: getAuthToken
	// }
});
