export const reportList = [
	{
		nepali: "जनसंख्या सम्बन्धी",
		english: "Population Related",
		value: "populationReport",
		baseApiKey: "family-detail",
		child: [
			{
				nepali: "उमेर अनुसार जनसंख्या",
				english: "Age wise population info",
				value: "age",
				title: "family",
				table: "genderWise",
			},
			{
				nepali: "घरमुली अनुसार विवरण",
				english: "House Owner Detail",
				value: "houseOwnerGender",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "वडागत घरधुरी संख्या तथा लिङ्गको आधारमा जनसंख्या",
				english: "",
				value: "populationInfo",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "वडा अनुसार उमेर समूह विवरण",
				english: "Age wise population info",
				value: "ageWardWise",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "बसोबासको अवस्था",
				english: "Living Status",
				value: "livingtype",
				title: "family",
				table: "wardWise",
			},
			// {
			// 	nepali: "अनुपस्थित जनसंख्या भएका परिवार संख्याको विवरण",
			// 	english: "",
			// 	value: "livingTypeDetail",
			// 	title: "family",
			// 	table: "wardWise",
			// },
			{
				nepali: "स्वदेश अन्यत्र",
				english: "Same Place",
				value: "livingSamePlace",
				title: "family",
				table: "wardWise",
			},
			// {
			// 	nepali: "परिवारको सदश्य वैदेशिक बसाईको आधारमा",
			// 	english: "",
			// 	value: "familyLivingAbroad",
			// 	title: "family",
			// 	table: "wardWise",
			// },
			{
				nepali: "विदेश गएको देश",
				english: "Foreign Country",
				value: "abroad",
				title: "family",
				table: "genderWise",
			},
			{
				nepali: "विदेश गएको अवधिको आधारमा जनसंख्या विवरण",
				english: "Abroad age",
				value: "abroadAge",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "विदेशमा वस्ने सदश्य संख्याको आधारमा",
				english: "Foreign Country",
				value: "abroadPopulation",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "विदेश गएको कारण",
				english: "Abroad Reason",
				value: "abroadReason",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "जातजाति अनुसार जनसंख्या",
				english: "Caste Report",
				value: "ethnics",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "धर्म अनुसार जनसंख्या",
				english: "Religion Report",
				value: "religion",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "मातृभाषा अनुसार जनसंख्या",
				english: "Mother tounge Report",
				value: "mothertongue",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "सामाजिक सुरक्षा भत्ता अनुसार जनसंख्या",
				english: "Social Welfare Report",
				value: "bhatta",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "शैक्षिकस्तर अनुसार विवरण",
				english: "Education Report (more than 4 years)",
				value: "education",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "हाल विद्यालय जाने/नजाने",
				english: "School going/notgoing Report (4 - 16 years)",
				value: "isSchoolGoing",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "पाँचबर्ष भन्दा माथिको साक्षरता विवर",
				english: "",
				value: "aboveFiveYearLiterate",
				title: "family",
				table: "wardWise",
			},
			// {
			// 	nepali: "५-२४ उमेर समूहका जनसंख्यामा हाल स्कुल नजाने",
			// 	english: "(5 - 24 years)",
			// 	value: "ageWiseIsSchool",
			// 	title: "family",
			// 	table: "wardWise",
			// },
			{
				nepali: "अध्ययनको क्षेत्र",
				english: "Study Field",
				value: "educationSector",
				title: "family",
				table: "educationTable",
			},
			{
				nepali: "पेशा सम्बन्धि विवरण",
				english: "Occupation Report (10 years above)",
				value: "occupation",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "आर्थिक रुपले सक्रिय जनसंख्या विवरण",
				english: "Education Report (more than 4 years)",
				value: "employedPopulation",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "व्यावसायिक कार्यको आधारमा विवरण",
				english: "Business Occupation Report",
				value: "businessOccupation",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "नोकरी/जागिरको आधारमा विवरण",
				english: "Jobs Report",
				value: "jobOccupation",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "वैवाहिक स्तिथि",
				english: "Marriage Report",
				value: "martial",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "वाल विवाहा भएका वाल वालिका वा युवायुवती संख्या",
				english: "",
				value: "earlyMarriage",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "परिवारका सदस्यहरुको स्वास्थ्य अवस्थाको आधारमा",
				english: "Health Condition Report",
				value: "health",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "दिर्घ रोगको किसिमको आधारमा",
				english: "Unhealthy Condition Report",
				value: "unHealthy",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "अपांगता भएको/नभएको आधारमा जनसंख्या विवरण",
				english: "Disable yes/no Report",
				value: "disableYesNo",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "अपांगता हुनुको कारण अनुसार",
				english: "Disable Reason Report",
				value: "disableReason",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "अपांगताको किसिमको आधारमा",
				english: "Disable Type Report",
				value: "disable",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "अपांगता कार्ड प्राप्त अनुसार जनसंख्या विवरण",
				english: "Disable Card Yes/No Report",
				value: "disableCardYesNo",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "अपांगताको कार्डको प्रकारको आधारमा",
				english: "Disable Card Type Report",
				value: "disableCards",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "व्यावसायिक शिप/तालिम लिएको/नलिएको आधारमा",
				english: "Skill Yes/No Report",
				value: "skillYesNo",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "शिप/तालिमको प्रकारको आधारमा",
				english: "Skill Report",
				value: "skill",
				title: "family",
				table: "wardWise",
			},
			{
				nepali:
					"शिपमुलक तालिम लिए पश्चात सोही क्षेत्रम काम गर्दै हुनु हुन्छको आधारमा",
				english: "",
				value: "isWorkFromSkill",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "विमा गरेको/नगरेको आधारमा",
				english: "Insurance Yes/No Report",
				value: "isInsurance",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "विमाको प्रकारको आधारमा",
				english: "Insurance Type Report",
				value: "insurance",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "रगत समुहको आधारमा विवरण",
				english: "Blood Group Report",
				value: "bloodGroup",
				title: "family",
				table: "wardWise",
			},
		],
	},
	{
		nepali: "मृत्यु सम्बन्धि",
		english: "Death Report",
		value: "deathReport",
		baseApiKey: "family-detail",
		child: [
			{
				nepali: "मृत्यु भएका परिवारका सदस्यहरुको संख्याको आधारमा घरधुरी विवरण",
				english: "Death of family member house count",
				value: "deathTotal",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "उमेर र लिङ्ग अनुसार मृत्यु संख्याको विवरण",
				english: "Death of family member age wise count",
				value: "deathAge",
				title: "family",
				table: "genderWise",
			},
			{
				nepali: "मृत्युको कारण अनुसार",
				english: "Death of family cause/disease wise count",
				value: "deathDisease",
				title: "family",
				table: "wardWise",
			},
			{
				nepali: "मृत्यु दर्ता सम्बन्धी",
				english: "Death Certificate",
				value: "deathCertificate",
				title: "family",
				table: "wardWise",
			},
		],
	},
	{
		nepali: "परिवारले प्रयोग गर्ने",
		english: "Family Related Detail",
		value: "familyReport",
		baseApiKey: "house-detail",
		child: [
			{
				nepali: "परिवारको यहाको बसोबास विवरण",
				english: "",
				value: "livingStatus",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "खाना पकाउन प्रयोगहुने प्रमुख ईन्धन चुलोको आधारमा घरधुरी विवरण",
				english:
					"Household details based on the main fuel stove used for cooking",
				value: "cookingSource",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "खाने पानीको आधारमा",
				english: "Based on drinking water",
				value: "waterresource",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "पानि लिनजना, भर्ना र फर्कन लाग्ने समय अवधि",
				english: "Period of water intake, admission and return",
				value: "distanceToWaterSource",
				title: "house",
				table: "wardWise",
			},
			// {
			// 	nepali: "पिउने पानीको शुद्धिकरण",
			// 	english: "Purification of drinking water",
			// 	value: "waterPurification",
			// 	title: "house",
			// 	table: "wardWise",
			// },
			{
				nepali: "बत्तीको मुख्य श्रोत",
				english: "Main source of light",
				value: "electricSource",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "विधुतीय मिटर जडान",
				english: "electricity meter",
				value: "isElectricityMeter",
				title: "house",
				table: "wardWise",
			},
			// {
			// 	nepali: "नुनको प्रयोग सम्बन्धी",
			// 	english: "Salt Used",
			// 	value: "saltUsed",
			// 	title: "house",
			// 	table: "wardWise",
			// },
			{
				nepali: "शौचालय भएको/नभएको विवरण",
				english: "Details of having / not having toilet",
				value: "isToilet",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "शौचालयको किसिम",
				english: "Type of toilet",
				value: "toilet",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "शौचालय नभएको कारण",
				english: "Due to lack of toilet",
				value: "noToilet",
				title: "house",
				table: "wardWise",
			},
			// {
			// 	nepali: "तपाईको घरमा हप्तामा कति फोहरमैला उत्पादन हुन्छ ?",
			// 	english: "Garbage coming out of the house",
			// 	value: "wasteProduction",
			// 	title: "house",
			// 	table: "wardWise",
			// },
			{
				nepali: "घरबाट निस्किने फोहोरमैला विवरण",
				english: "Garbage coming out of the house",
				value: "garbage",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "घर सम्म पुग्ने सडक/बाटोको अवस्था",
				english: "Condition of the road leading to the house",
				value: "road",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "परिवारले प्रयोग गर्ने सुविधाहरुको विवरण",
				english: "Details of facilities used by the family",
				value: "houseFacility",
				title: "house",
				table: "wardWise",
			},
			// {
			// 	nepali: "८.१ मोटरसाइकल/स्कुटर - संख्या",
			// 	english: "Motor Cycle Count",
			// 	value: "motorCycleCount",
			// 	title: "house",
			// 	table: "wardWise",
			// },
		],
	},
	{
		nepali: "घर सम्बन्धि विवरण",
		english: "House Related Detail",
		value: "houseReport",
		baseApiKey: "house-detail",
		child: [
			{
				nepali: "घरको संख्याको आधारमा विवरण",
				english: "Details based on the number of houses",
				value: "houseCount",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "घरको तल्लाको आधारमा विवरण",
				english: "Details based on the floor of the house",
				value: "totalStorey",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "घरको कोठा संख्याको आधारमा विवरण",
				english: "Details based on the number of rooms in the house",
				value: "totalRoom",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "घर निर्माण वर्षको आधारमा विवरण",
				english: "Details based on age of house",
				value: "houseAge",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "घर भाडामा दिनु भएको छ कि छैन",
				english: "Based on the purpose of renting the house",
				value: "isRent",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "घरभाडा दिएको प्रयोजनको आधारमा",
				english: "Based on the purpose of renting the house",
				value: "rentGivenTo",
				title: "house",
				table: "wardWise",
			},
			// {
			// 	nepali: "सदस्यको नाम/परिवारको अन्य स्थानमा घर भएको विवरण",
			// 	english: "Details of the family's member home in another place",
			// 	value: "homeOtherPlace",
			// 	title: "house",
			// 	table: "wardWise",
			// },
			// {
			// 	nepali: "घरको स्वामित्वको आधारमा",
			// 	english: "Details based on home ownership",
			// 	value: "houseUsedLiving",
			// 	title: "house",
			// 	table: "wardWise",
			// },
			// {
			// 	nepali: "घरको बनावटको आधारमा",
			// 	english: "Details based on home ownership",
			// 	value: "houseType",
			// 	title: "house",
			// 	table: "wardWise",
			// },
			{
				nepali: "घरको जग कस्तो प्रकारको विवरण",
				english: "Details based on home ownership",
				value: "houseFoundation",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "घरको छानाको बनावटको आधारमा",
				english: "Details based on the texture of the roof",
				value: "rooftype",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "घर भवन संहिता",
				english: "House building criteria",
				value: "houseCriteriaFullfill",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "घरको जोखिम प्रकारको आधारमा",
				english: "Depending on the type of home risk",
				value: "houseRiskManagement",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "घरको परिवारको जोखिम पारिवारिक योजनाको विवरण",
				english: "Depending on the type of home risk",
				value: "houseRiskPlanning",
				title: "house",
				table: "wardWise",
			},
			// {
			// 	nepali: "घर निर्माण वर्षको आधारमा विवरण",
			// 	english: "Details based on house construction year",
			// 	value: "houseRiskManagement",
			// 	title: "house",
			// 	table: "wardWise"
			// },
			{
				nepali: "घर निर्माण प्रमाण पत्र लिएको/नलिएको",
				english: "Housing certificate taken / not taken",
				value: "houseCertificate",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "घर नक्शा पास गरेको/नगरेको",
				english: "House map passed / not passed",
				value: "houseMapPass",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "परिवारमा महिलाको नाममा घर, जग्गाको विवरण",
				english: "House map passed / not passed",
				value: "womenHouseOwnership",
				title: "house",
				table: "wardWise",
			},
		],
	},
	{
		nepali: "जग्गा सम्बन्धि विवरण",
		english: "Land Related Detail",
		value: "landReport",
		baseApiKey: "house-detail",
		child: [
			{
				nepali: "वन प्रयोजनको लागी जग्गा प्रयोग गर्नु भएको छ कि छैन ?",
				english: "Total land area",
				value: "isForestUse",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "आफ्नो जग्गा कसरी उपभोग गर्नु भएको विवरण",
				english: "Total land area",
				value: "landConsumption",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "कृषि कार्यमा उपभोग जग्गा सम्बन्धी विवरण",
				english: "Total land area",
				value: "familyLandConsumption",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "भोगचलनको आधारमा जम्मा जग्गाको विवरण (ऐलानी, नम्बरी)",
				english: "Total land area",
				value: "totalLandArea",
				title: "house",
				table: "wardWise",
			},
		],
	},
	{
		nepali: "आर्थिक सम्बन्धि",
		english: "Economics Related",
		value: "economicsReport",
		baseApiKey: "house-detail",
		child: [
			{
				nepali:
					"तपाईको परिवारले कृषिबाहेक कुनै मुख्य साना घरेलु व्यावसाय चलाएको छ ?",
				english: "",
				value: "smallHomeBusiness",
				title: "house",
				table: "wardWise",
			},
			{
				nepali:
					"विधुतीय माध्यमको प्रयोग गरेर कुन कुन सेवा सुबिधाहरुको भुक्तानी गरि रहनु भएको विवरण",
				english: "",
				value: "electronicTransaction",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: " परिवारको मुख्य आम्दानीको स्रोतको विवरण",
				english: "",
				value: "mainIncomeSource",
				title: "house",
				table: "wardWise",
			},

			{
				nepali: "परिवारको सरदर वार्षिक आम्दानीको विवरण",
				english: "",
				value: "annualIncome",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "परिवारको सरदर वार्षिक खर्चको विवरण",
				english: "",
				value: "annualExpense",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "परिवारमा बैंक तथा वित्तीय संस्थामा खाता भएको/नभएको विवरण",
				english:
					"Details of family having bank or financial institution account",
				value: "bankAccountYesNo",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "बैंक खाता हुने परिवार सदस्य संख्या अनुसार विवरण",
				english:
					"Details according to the number of family members having a bank account",
				value: "bankAccountGender",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "ऋण लिएको/नलिएको आधारमा",
				english: "",
				value: "isLoan",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "ऋण लिएको श्रोतको आधारमा",
				english: "",
				value: "loanTaken",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "ऋणलिएको  उदेश्यको आधारमा विवरण",
				english: "",
				value: "loanTakingReason",
				title: "house",
				table: "wardWise",
			},
		],
	},
	{
		nepali: "विविध सामाजिक विवरण",
		english: "",
		value: "socialReport",
		baseApiKey: "house-detail",
		child: [
			{
				nepali: "कोरोना महामारीले परिवारमा परेको असर",
				english: "",
				value: "effectFromPandemic",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "गाउँपालिकाको सूचनाकेबाट प्राप्त गर्नुहुन्छ",
				english: "",
				value: "meansOfInformation",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "परिवार वा टोल छिमेकमा हुने विवाद वा झैझगडाको उजुरीको विवरण",
				english: "",
				value: "complain",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "परिवारमा कोही द्दन्द पिडित हुनुहुन्छ",
				english: "",
				value: "houseConflict",
				title: "house",
				table: "wardWise",
			},
		],
	},
	// {
	// 	nepali: "स्वास्थ्य सम्बन्धि",
	// 	english: "Health Related",
	// 	value: "healthReport",
	// 	baseApiKey: "house-detail",
	// 	child: [
	// 		{
	// 			nepali: "घरमा बीरामी परेमा सबैभंदा पहिला जाने स्थानको आधारमा",
	// 			english: "",
	// 			value: "healthCheckup",
	// 			title: "house",
	// 			table: "wardWise",
	// 		},
	// 	],
	// },
];
